<template>
    <div role="tabpanel">
        <div class="py-4 text-center">
            <lottie class="avatar-xl" colors="primary:#121331,secondary:#08a88a" :options="defaultOptions"
                :height="75" :width="75" />
            <h5 class="mt-2">{{ title }}</h5>
            <p class="text-muted mb-0">{{ text }}</p>
        </div>
    </div>
</template>

<script>
import Lottie from "@/components/widgets/lottie.vue";
import animationData from "@/components/widgets/msoeawqm.json";

export default {
  components: {
    lottie: Lottie
  },
  props: {
    title: {
      type: String,
      default: "Humm! Parece que não temos nenhum registro aqui"
    },
    text: {
      type: String,
      default: "Procuramos em toda base e não achamos nada, tente alterar os filtros ou criar algum registro"
    }
  },
  data() {
    return {
        defaultOptions: { animationData }
    }
  }
};
</script>