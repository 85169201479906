<script>
  import Swal from "sweetalert2";
  import Layout from "@/layouts/main.vue";
  import appConfig from "@/../app.config";
  import PageHeader from "@/components/page-header";
  import { callUrl } from '@/helpers/api-apolo';
  import EmptyTableResult from "@/components/empty-table-result";
  import { formatPrice, saError } from '@/helpers/global-functions';
  import { overlay } from "@/state/helpers";
  import * as helpers from '@/helpers/helper-functions';

  export default {
    page: {
      title: "Produtos",
      meta: [{
        name: "description",
        content: appConfig.description,
      }, ],
    },
    data() {
      return {
        title: "Produtos",
        items: [{
            text: "CRM",
            href: "/",
          },
          {
            text: "Produtos",
            active: true,
          },
        ],
        ids: [],
        query: '',
        productsData: [],
        page: 1,
        perPage: 10
      };
    },
    computed: {
      pages() {
        if (this.page === 1 && !this.productsData.length) {
          return [];
        }
        if (this.page === 1 && this.productsData.length < this.perPage) {
          return [1];
        }
        if (this.page === 1 && this.productsData.length === this.perPage) {
          return [1, 2];
        }
        if (this.page > 1 && (!this.productsData.length || this.productsData.length < this.perPage)) {
          return [this.page - 1, this.page];
        }
        return [this.page - 1, this.page, this.page + 1];
      }
    },
    watch: {
      page() {
        this.getAllProducts();
      },
      query(value) {
        if (value === '' || value.length === 0) {
          this.getAllProducts()
        }
      }
    },
    methods: {
      ...overlay,
      ...helpers,
      formatPrice(value) {
        return formatPrice(value);
      },
      reset () {
        this.query = ''
        this.getAllProducts()
      },
      search() {
        if (this.page !== 1) {
          this.page = 1;
        } else {
          this.getAllProducts();
        }
      },
      async getAllProducts() {
        try {
          this.changeShowOverlay({show: true});
          this.productsData = [];
          const params = [`page=${this.page}`, `limit=${this.perPage}`];
          if (this.query.length > 0) {
            params.push(`query=${this.query}`);
          }
          const dataM = await callUrl({}, '/product?' + params.join('&'), 'get')
          if (dataM && dataM.length) {
            this.productsData = dataM;
          }
        } catch (error) {
          console.log(error.message);
        }
        this.changeShowOverlay({show: false});
      },
      checkIdToDelete(id) {
        if (this.ids.filter(value => value === id).length) {
          this.ids = this.ids.filter(value => value !== id);
          return;
        }
        this.ids.push(id);
      },
      checkAllIdToDelete() {
        this.ids = [];
        const checkboxes = document.querySelectorAll(
          '.form-check-all input[type="checkbox"]'
        );
        if (document.getElementById('checkAll').checked) {
          this.ids = this.productsData.map(obj => obj.id);
          checkboxes.forEach(function (checkbox) {
            checkbox.checked = true;
          });
          return;
        }
        checkboxes.forEach(function (checkbox) {
          checkbox.checked = false;
        });
      },
      deleteMultiple() {
        if (this.ids.length > 0) {
          if (confirm("Você tem certeza que deseja remover todos os registros selecionados?")) {
            // TODO: implementar!
          }
          return;
        }
        Swal.fire({
          title: "Por favor, selecione ao menos um registro para remoção",
          confirmButtonClass: "btn btn-info",
          buttonsStyling: false,
          showCloseButton: true
        });
      },
      async deletedata(obj) {
        Swal.fire({
          title: "Você tem certeza?",
          html: "Será removido o produto <b>" + obj.name + "</b><br>Essa é uma operação irreversível!!!",
          icon: "warning",
          reverseButtons: true,
          showCancelButton: true,
          confirmButtonText: "Sim, pode remover!",
          cancelButtonText: "Cancelar",
          buttonsStyling: false,
          customClass: {
            actions: 'd-flex justify-content-center',
            confirmButton: 'btn btn-success flex-grow-1',
            cancelButton: 'btn btn-outline-dark flex-grow-1'
          }
        }).then(async (result) => {
          if (result.value) {
            try {
              this.changeShowOverlay({show: true});
              await callUrl({}, '/product/' + obj.id, 'delete')
              this.changeShowOverlay({show: false});
              Swal.fire("Sucesso!", "Removido com sucesso", "success");
              this.page = 1;
              this.getAllProducts()
            } catch (error) {
              this.changeShowOverlay({show: false});
              saError(error.message);
            }
          }
        });
      }
    },
    mounted() {
      this.getAllProducts();
    },
    components: {
      Layout,
      PageHeader,
      EmptyTableResult
    },
  };
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    
    <div class="row">
      <div class="col-xl-12 col-lg-12">
        <div>
          <div class="card">
            <div class="card-header border-0">
              <div class="d-flex align-items-center">
                <h5 class="card-title mb-0 flex-grow-1"></h5>
                <div class="flex-shrink-0">
                  <button v-if="ids.length" class="btn btn-soft-danger me-1" @click="deleteMultiple()">
                    <i class="ri-delete-bin-2-line"></i>
                  </button>
                  <router-link to="/apps/crm-add-product" class="btn btn-success"><i
                        class="ri-add-line align-bottom me-1"></i> Adicionar Produto</router-link>
                </div>
              </div>
            </div>

            <div class="card-body bg-soft-light border border-dashed border-start-0 border-end-0">
              <form>
                <div class="row g-3">
                  <div class="col-xl-5 col-lg-5 col-md-6 col-sm-6 col-7">
                    <div class="search-box">
                      <input type="text" class="form-control search" v-model="query" placeholder="Busca por algum termo..." @keydown.enter.prevent="getAllProducts()">
                      <i class="ri-search-line search-icon"></i>
                      <i class=" ri-close-line search-icon" style="right: 7px !important; left: unset !important; cursor: pointer !important;" v-show="query.length > 0" @click="reset()"></i>
                    </div>
                    <div class="form-text" v-show="query.length > 0">Aperte ENTER para buscar.</div>
                  </div>
                  <div class="col-xl-5 col-lg-5 col-md-4 col-sm-3 col-1"></div>
                  <div class="col-xl-2 col-lg-2 col-md-2 col-sm-3 col-4">
                    <button type="button" class="btn btn-primary w-100" @click="search()">
                      <i class="mdi mdi-magnify me-1 align-bottom"></i> Buscar
                    </button>
                  </div>
                </div>
              </form>
            </div>
            
            <div class="card-body">
              <div class="tab-content">
                <div class="tab-pane active" id="productnav-all" role="tabpanel">
                  <div id="table-product-list-all" class="table-card gridjs-border-none table-responsive">
                    <table class="table align-middle table-nowrap table-striped mb-0" id="customerTable">
                      <thead class="table-primary">
                        <tr class="text">
                          <th scope="col" style="width: 50px">
                            <input class="form-check-input" type="checkbox" id="checkAll" value="1" @change="checkAllIdToDelete()" />
                          </th>
                          <th>Produto</th>
                          <th>Preço</th>
                          <th>Fotos</th>
                          <th>Vídeos</th>
                          <th>Publicado em</th>
                          <th scope="col">Ações</th>
                        </tr>
                      </thead>
                      <tbody class="list form-check-all">
                        <tr class="gridjs-tr" v-for="(item, index) of productsData" :key="index">
                          <td data-column-id="productListAllCheckbox" class="gridjs-td">
                            <input type="checkbox" name="chk_child" class="form-check-input" :value="item.id" @change="checkIdToDelete(item.id)" />
                          </td>
                          <td data-column-id="product" class="gridjs-td">
                            <span>
                              <div class="d-flex align-items-center">
                                <div class="flex-shrink-0 me-3">
                                  <div class="avatar-sm bg-light rounded p-1">
                                    <img v-if="item.photos.length == 0" src="@/assets/images/products/without-photo.jpg" alt="Sem foto" class="img-fluid d-block" />
                                    <img v-if="item.photos.length > 0" :src="item.photos[0].path" :alt="item.photos[0].name" class="img-fluid d-block" />
                                  </div>
                                </div>
                                <div class="flex-grow-1">
                                  <h5 class="fs-14 mb-1">{{ item.name }}</h5>
                                  <p class="text-muted mb-0">
                                    Código : <span class="fw-medium">{{ item.code }}</span>
                                  </p>
                                </div>
                              </div>
                            </span>
                          </td>
                          <td data-column-id="price" class="gridjs-td">{{ formatPrice(item.price) }}</td>
                          <td data-column-id="price" class="gridjs-td">{{ item.photos.length }}</td>
                          <td data-column-id="price" class="gridjs-td">{{ item.videos.length }}</td>
                          <td data-column-id="published" class="gridjs-td">
                            {{ translateDateOnly(item.created_at) }} <small class="text-muted">{{ translateTimeOnly(item.created_at) }}</small>
                          </td>
                          <td data-column-id="action" class="gridjs-td">
                            <span>
                              <div class="dropdown">
                                <button class="btn btn-soft-secondary btn-sm dropdown" type="button"
                                  data-bs-toggle="dropdown" aria-expanded="false">
                                  <i class="ri-more-fill"></i>
                                </button>
                                <ul class="dropdown-menu dropdown-menu-end">
                                  <li>
                                    <router-link class="dropdown-item" :to="'/apps/crm-add-product/' + item.id">
                                      <i class="ri-pencil-fill align-bottom me-2 text-muted"></i> Editar
                                    </router-link>
                                  </li>
                                  <li class="dropdown-divider"></li>
                                  <li>
                                    <a class="dropdown-item" @click="deletedata(item)" style="cursor: pointer">
                                      <i class="ri-delete-bin-fill align-bottom me-2 text-muted"></i> Apagar
                                    </a>
                                  </li>
                                </ul>
                              </div>
                            </span>
                          </td>
                        </tr>
                      </tbody>
                    </table>

                    <EmptyTableResult v-show="productsData.length == 0" />

                    <div class="d-flex justify-content-end m-3">
                      <div class="pagination-wrap hstack gap-2">
                        <a class="page-item pagination-prev" href="#" @click="--page" v-if="page > 1">Anterior</a>
                        <a class="page-item pagination-prev disabled" href="#" v-if="productsData.length && page == 1" @click.prevent="">Anterior</a>
                        <ul class="pagination listjs-pagination mb-0">
                          <li v-for="pageNumber in pages" :key="'page' + pageNumber" @click="page = pageNumber"
                            :class="{active: pageNumber == page, disabled: pageNumber != page}">
                            <a class="page" href="#">{{ pageNumber }}</a>
                          </li>
                        </ul>
                        <a class="page-item pagination-next" href="#" @click="++page" v-if="productsData.length == perPage">Próxima</a>
                        <a class="page-item pagination-next disabled" href="#" @click.prevent="" v-if="productsData.length && productsData.length < perPage">Próxima</a>
                      </div>
                    </div>
                  </div>
                </div>
                
              </div>
              <!-- end tab content -->
            </div>
            <!-- end card body -->
          </div>
          <!-- end card -->
        </div>
      </div>
      <!-- end col -->
    </div>
    <!-- end row -->
  </Layout>
</template>
